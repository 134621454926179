import React from 'react'
import Navigation from '../../components/Navigation'

const SeasonRosters = () => {
  return (
    <div>
        <Navigation />
        <div>SeasonRosters</div>
    </div>
  )
}

export default SeasonRosters