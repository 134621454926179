import React from 'react'
import Navigation from '../components/Navigation'

const Events = () => {
  return (
    <div>
        <Navigation />
        <div>Events</div>
    </div>
  )
}

export default Events