import React from 'react'
import Navigation from '../../components/Navigation'

const EventDrafts = () => {
  return (
    <div>
        <Navigation />
        <div>EventDrafts</div>
    </div>
  )
}

export default EventDrafts