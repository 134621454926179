export const trendingPlayers = [
    {
        startgg_image: "https://images.start.gg/images/user/11492/image-3dd9789eb28e9bef8b6f8b572dd23389.jpeg?ehk=yWoe%2B5bbozHkCky6qBhZxdf8SPxOjJkqVd4FM9xcjts%3D&ehkOptimized=1vKUZGZtc7wtkl4zLyUzd9JhAoO1sJ5RzQSvqHc61EA%3D",
        playerTag: "Esam",
        country: "USA",
        characters: {
            main: "Pikachu",
            secondaries: "Rob, Palutena"
        },
        state: null,
        no_events_participated: 479,
        win_loss_ratio: "189 - 21"
    },
    {
        startgg_image: "https://images.start.gg/images/user/11492/image-3dd9789eb28e9bef8b6f8b572dd23389.jpeg?ehk=yWoe%2B5bbozHkCky6qBhZxdf8SPxOjJkqVd4FM9xcjts%3D&ehkOptimized=1vKUZGZtc7wtkl4zLyUzd9JhAoO1sJ5RzQSvqHc61EA%3D",
        playerTag: "Esam",
        country: "USA",
        characters: {
            main: "Pikachu",
            secondaries: "Rob, Palutena"
        },
        state: null,
        no_events_participated: 479,
        win_loss_ratio: "189 - 21"
    },
    {
        startgg_image: "https://images.start.gg/images/user/11492/image-3dd9789eb28e9bef8b6f8b572dd23389.jpeg?ehk=yWoe%2B5bbozHkCky6qBhZxdf8SPxOjJkqVd4FM9xcjts%3D&ehkOptimized=1vKUZGZtc7wtkl4zLyUzd9JhAoO1sJ5RzQSvqHc61EA%3D",
        playerTag: "Esam",
        country: "USA",
        characters: {
            main: "Pikachu",
            secondaries: "Rob, Palutena"
        },
        state: null,
        no_events_participated: 479,
        win_loss_ratio: "189 - 21"
    },
    {
        startgg_image: "https://images.start.gg/images/user/11492/image-3dd9789eb28e9bef8b6f8b572dd23389.jpeg?ehk=yWoe%2B5bbozHkCky6qBhZxdf8SPxOjJkqVd4FM9xcjts%3D&ehkOptimized=1vKUZGZtc7wtkl4zLyUzd9JhAoO1sJ5RzQSvqHc61EA%3D",
        playerTag: "Esam",
        country: "USA",
        characters: {
            main: "Pikachu",
            secondaries: "Rob, Palutena"
        },
        state: null,
        no_events_participated: 479,
        win_loss_ratio: "189 - 21"
    },
    {
        startgg_image: "https://images.start.gg/images/user/11492/image-3dd9789eb28e9bef8b6f8b572dd23389.jpeg?ehk=yWoe%2B5bbozHkCky6qBhZxdf8SPxOjJkqVd4FM9xcjts%3D&ehkOptimized=1vKUZGZtc7wtkl4zLyUzd9JhAoO1sJ5RzQSvqHc61EA%3D",
        playerTag: "Esam",
        country: "USA",
        characters: {
            main: "Pikachu",
            secondaries: "Rob, Palutena"
        },
        state: null,
        no_events_participated: 479,
        win_loss_ratio: "189 - 21"
    },
    {
        startgg_image: "https://images.start.gg/images/user/11492/image-3dd9789eb28e9bef8b6f8b572dd23389.jpeg?ehk=yWoe%2B5bbozHkCky6qBhZxdf8SPxOjJkqVd4FM9xcjts%3D&ehkOptimized=1vKUZGZtc7wtkl4zLyUzd9JhAoO1sJ5RzQSvqHc61EA%3D",
        playerTag: "Esam",
        country: "USA",
        characters: {
            main: "Pikachu",
            secondaries: "Rob, Palutena"
        },
        state: null,
        no_events_participated: 479,
        win_loss_ratio: "189 - 21"
    },
    {
        startgg_image: "https://images.start.gg/images/user/11492/image-3dd9789eb28e9bef8b6f8b572dd23389.jpeg?ehk=yWoe%2B5bbozHkCky6qBhZxdf8SPxOjJkqVd4FM9xcjts%3D&ehkOptimized=1vKUZGZtc7wtkl4zLyUzd9JhAoO1sJ5RzQSvqHc61EA%3D",
        playerTag: "Esam",
        country: "USA",
        characters: {
            main: "Pikachu",
            secondaries: "Rob, Palutena"
        },
        state: null,
        no_events_participated: 479,
        win_loss_ratio: "189 - 21"
    },
    {
        startgg_image: "https://images.start.gg/images/user/11492/image-3dd9789eb28e9bef8b6f8b572dd23389.jpeg?ehk=yWoe%2B5bbozHkCky6qBhZxdf8SPxOjJkqVd4FM9xcjts%3D&ehkOptimized=1vKUZGZtc7wtkl4zLyUzd9JhAoO1sJ5RzQSvqHc61EA%3D",
        playerTag: "Esam",
        country: "USA",
        characters: {
            main: "Pikachu",
            secondaries: "Rob, Palutena"
        },
        state: null,
        no_events_participated: 479,
        win_loss_ratio: "189 - 21"
    },
    {
        startgg_image: "https://images.start.gg/images/user/11492/image-3dd9789eb28e9bef8b6f8b572dd23389.jpeg?ehk=yWoe%2B5bbozHkCky6qBhZxdf8SPxOjJkqVd4FM9xcjts%3D&ehkOptimized=1vKUZGZtc7wtkl4zLyUzd9JhAoO1sJ5RzQSvqHc61EA%3D",
        playerTag: "Esam",
        country: "USA",
        characters: {
            main: "Pikachu",
            secondaries: "Rob, Palutena"
        },
        state: null,
        no_events_participated: 479,
        win_loss_ratio: "189 - 21"
    },
    {
        startgg_image: "https://images.start.gg/images/user/11492/image-3dd9789eb28e9bef8b6f8b572dd23389.jpeg?ehk=yWoe%2B5bbozHkCky6qBhZxdf8SPxOjJkqVd4FM9xcjts%3D&ehkOptimized=1vKUZGZtc7wtkl4zLyUzd9JhAoO1sJ5RzQSvqHc61EA%3D",
        playerTag: "Esam",
        country: "USA",
        characters: {
            main: "Pikachu",
            secondaries: "Rob, Palutena"
        },
        state: null,
        no_events_participated: 479,
        win_loss_ratio: "189 - 21"
    },
    
    
];

// export default trendingPlayers;