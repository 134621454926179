export const characters = {
    "Super Smash Bros. Ultimate": [
        "Mario",
        "Donkey Kong",
        "Link",
        "Samus",
        "Dark Samus",
        "Yoshi",
        "Kirby",
        "Fox", 
        "Pikachu",
        "Luigi",
        "Ness",
        "Captain Falcon",
        "Jigglypuff",
        "Peach",
        "Daisy",
        "Bowser",
        "Ice Climbers",
        "Sheik",
        "Zelda",
        "Dr. Mario",
        "Pichu",
        "Falco",
        "Marth",
        "Lucina",
        "Young Link",
        "Ganondorf",
        "Mewtwo",
        "Roy",
        "Chrom",
        "Mr. Game & Watch",
        "Meta Knight",
        "Pit",
        "Dark Pit",
        "Zero Suit Samus",
        "Wario",
        "Snake",
        "Ike",
        "Pokemon Trainer",
        "Diddy Kong",
        "Lucas",
        "Sonic",
        "King Dedede",
        "Olimar",
        "Lucario",
        "R.O.B.",
        "Toon Link",
        "Wolf",
        "Villager",
        "Mega Man",
        "Wii Fit Trainer",
        "Rosalina & Luma",
        "Little Mac",
        "Greninja",
        "Mii Fighter",
        "Palutena",
        "Pac-Man",
        "Robin",
        "Shulk",
        "Bowser Jr.",
        "Duck Hunt",
        "Ryu",
        "Ken",
        "Cloud",
        "Corrin",
        "Bayonetta",
        "Inkling",
        "Ridley",
        "Simon Belmont",
        "Richter",
        "King K. Rool",
        "Isabelle",
        "Incineroar",
        "Piranha Plant",
        "Joker",
        "Hero",
        "Banjo-Kazooie",
        "Terry Bogard",
        "Byleth",
        "Min Min",
        "Steve/Alex",
        "Sephiroth",
        "Pyra/Mythra",
        "Kazuya",
        "Sora"
    ],
    "Super Smash Bros. Melee": [
        "Dr. Mario",
        "Mario",
        "Luigi",
        "Bowser",
        "Peach",
        "Yoshi",
        "Donkey Kong",
        "Captain Falcon",
        "Ganondorf",
        "Falco",
        "Fox",
        "Ness",
        "Ice Climbers",
        "Kirby",
        "Samus",
        "Zelda",
        "Sheik",
        "Link",
        "Young Link",
        "Pichu",
        "Pikachu",
        "Jigglypuff",
        "Mewtwo",
        "Mr. Game & Watch",
        "Marth",
        "Roy"
    ]
}