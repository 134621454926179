import React from 'react'
import Navigation from '../../components/Navigation'

const TrendsAndInsights = () => {
  return (
    <div>
        <Navigation />
        <div>TrendsAndInsights</div>
    </div>
  )
}

export default TrendsAndInsights